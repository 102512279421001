





























import { Component, Prop, Vue } from 'vue-property-decorator'

import ChartBase from '@/components/charts/ChartBase.vue'
import StatusIcon from '@/components/StatusIcon.vue'
import { getPercent } from '@/utils/functions'

@Component({
  components: {
    ChartBase,
    StatusIcon,
  },
})
export default class ChartExercise extends Vue {
  @Prop({ required: true })
  private total!: number

  @Prop({ required: true })
  private passed!: number

  @Prop({ required: true })
  private completed!: number

  @Prop({ required: true })
  private wait!: number

  @Prop({ default: false })
  private showNotPassed!: boolean

  @Prop({ default: false })
  private hovered!: boolean

  private get isDone () {
    return this.total === this.completed && this.total === this.passed
  }

  private getPercent (total: number, completion: number) {
    return getPercent(total, completion)
  }
}
