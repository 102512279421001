





import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class StatusIcon extends Vue {
  @Prop({
    default: 'success',
    validator (value: string): boolean {
      return !!value.match(/(success|warning|error)/)
    },
  })
  private status!: string

  @Prop({ default: true })
  private visible!: boolean

  private innerValues = {
    error: '<svg class="svg" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">'
      + '<path d="M8 20c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12S8 26.627 8 20z" fill="#F44336"/>'
      + '<path d="M21.41 20l4.3-4.29a1.004 1.004 0 10-1.42-1.42L20 18.59l-4.29-4.3a1.004 1.004 0 10-1.42 1.42l4.3 4.29-4.3 4.29a1.002 1.002 0 00.325 1.639.999.999 0 001.095-.219l4.29-4.3 4.29 4.3a1.001 1.001 0 001.639-.325 1 1 0 00-.22-1.095L21.41 20z" fill="#fff"/></svg>',
    success: '<svg class="svg" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">'
      + '<path d="M8 20c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12S8 26.627 8 20z" fill="#56C0AD"/>'
      + '<path d="M26.71 15.21a.997.997 0 00-1.094-.219.998.998 0 00-.326.219l-7.45 7.46-3.13-3.14a1.02 1.02 0 00-1.66.36 1.02 1.02 0 00.24 1.11l3.84 3.84a1.001 1.001 0 001.42 0l8.16-8.16a1 1 0 000-1.47z" fill="#fff"/></svg>',
    warning: '<svg class="svg" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">'
      + '<path d="M31.545 25.034l-8.73-15.347a3.273 3.273 0 00-1.195-1.234 3.227 3.227 0 00-3.295 0c-.5.296-.912.722-1.194 1.234L8.455 25.034a3.315 3.315 0 00-.034 3.295c.282.504.69.924 1.183 1.217.494.293 1.055.45 1.627.454h17.483a3.226 3.226 0 001.646-.433 3.27 3.27 0 001.206-1.214 3.315 3.315 0 00-.021-3.32z" fill="#FB8C00"/>'
      + '<path d="M19.444 24.169a1 1 0 111.111 1.662 1 1 0 01-1.11-1.662zM19.293 15.293A1 1 0 0121 16v5a1 1 0 01-2 0v-5a1 1 0 01.293-.707z" fill="#fff"/></svg>',
  }
}
