




























































import { Component, Mixins, Prop, Ref } from 'vue-property-decorator'

import ChartExercise from '@/components/charts/ChartExercise.vue'
import Confirmation from '@/components/modals/Confirmation.vue'
import Tag from '@/components/_uikit/Tag.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import { ExerciseAccessLevel, ExerciseManagerCardResource } from '@/store/types'
import MentorExercisesModule from '@/store/modules/mentor/exercises'
import AuthModule from '@/store/modules/auth'

@Component({
  components: {
    ChartExercise,
    Confirmation,
    Tag,
  },
})
export default class MentorExerciseCard extends Mixins(NotifyMixin) {
  @Ref() confirm!: Confirmation

  @Prop({ required: true })
  private exercise!: ExerciseManagerCardResource

  @Prop({ required: true })
  private masterGroupId!: number

  @Prop({ default: true })
  private managePersonal!: boolean

  @Prop({ default: null })
  private redirectObject!: any

  private get task () {
    return this.exercise && this.exercise.tasks.length > 0 ? this.exercise.tasks[0] : null
  }

  private get total () {
    return this.exercise.wait + this.exercise.onCheck + this.exercise.checked + this.exercise.completed
  }

  private get isDone () {
    return this.total === this.exercise.completed
  }

  private get isPersonal () {
    return this.exercise.accessLevel.value === ExerciseAccessLevel.PERSONAL
  }

  private get exerciseTitle() {
    return this.exercise.tasks.length > 1 ? 'Пробник' : this.task ? this.task.type.name : 'Нет заданий'
  }

  private get taskTitle() {
    return this.exercise.title
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private handleEdit () {
    this.$router.push({
      name: 'manager.education.exercises.item.edit',
      params: {
        exerciseUUID: this.exercise.uuid,
        groupID: this.masterGroupId.toString(),
      },
    })
  }

  private confirmDelete () {
    this.confirm.open(
      'Удаление индивидуального задания',
      'Вы действительно хотите удалить выбранное индивидуальное задание?',
      {
        buttonConfirmText: 'Удалить',
      },
    )
      .then(this.handleDelete)
      .catch(() => {return})
  }

  private handleDelete () {
    MentorExercisesModule.deleteExercise({
      exerciseUUID: this.exercise.uuid,
      masterGroupID: this.masterGroupId,
    })
      .then(() => {
        this.notifySuccess('Индивидуальное задание удалено')
        this.$emit('delete')
      })
      .catch(this.notifyError)
  }
}
