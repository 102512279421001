





























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class ChartBase extends Vue {
  @Prop({ default: false })
  private large!: boolean

  @Prop({ default: 'teal' })
  private skinPrimary!: 'teal'|'blue'|'orange'

  @Prop({ default: null })
  private skinSecondary!: 'teal'|'blue'|'orange'|null

  @Prop({ default: null })
  private percentPrimary!: number

  @Prop({ default: null })
  private percentSecondary!: number

  private get size () {
    return this.large ? 104 : 56
  }

  private get radius () {
    return this.size / 2
  }

  private get circleLength () {
    return this.radius * 2 * Math.PI
  }

  private get strokeWidthPrimary () {
    return this.large ? 13 : 8
  }

  private get strokeWidthSecondary () {
    return this.large ? 21 : 13
  }

  private get strokeColorPrimary () {
    switch (this.skinPrimary) {
    case 'blue': return '#6B9EFF'
    case 'orange': return '#FFA552'
    case 'teal': return '#56C0AD'
    default: return '#56C0AD'
    }
  }

  private get strokeColorSecondary () {
    switch (this.skinSecondary) {
    case 'blue': return '#6B9EFF'
    case 'orange': return '#FFA552'
    case 'teal': return '#56C0AD'
    default: return '#56C0AD'
    }
  }
}
