import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import {
  EducationMasterGroupResource,
  ManagerMasterGroupsSelectGetRequest,
} from '@/store/types'
import store from '@/store'

/**
 *  Модуль затрагивает профиль сотрудника
 * - todo
 */

@Module({
  dynamic: true,
  name: 'MentorEmployee',
  namespaced: true,
  store,
})
class MentorEmployee extends VuexModule {
  // ---------------------------- Masters ---------------------------- >>
  employeeMasterGroups: EducationMasterGroupResource[] = []
  currentMasterGroup: EducationMasterGroupResource | null = null

  @Mutation
  setEmployeeMasterGroups(payload: EducationMasterGroupResource[]) {
    this.employeeMasterGroups = [...payload]
  }

  @Mutation
  setEmployeeCurrentMasterGroup(payload: EducationMasterGroupResource) {
    this.currentMasterGroup = payload
  }

  @Action({ rawError: true })
  async fetchEmployeeMasterGroups(mentorId: number): Promise<EducationMasterGroupResource[]> {
    const { data } = await ManagerMasterGroupsSelectGetRequest({ mentorId: mentorId })
    this.setEmployeeMasterGroups(data)
    if (data.length > 0) {
      this.setEmployeeCurrentMasterGroup(data[0])
    }
    return data
  }
}

const MentorEmployeeModule = getModule(MentorEmployee)

export default MentorEmployeeModule
