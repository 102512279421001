







































import { Component, Watch } from 'vue-property-decorator'

import MentorExerciseCard from '@/components/cards/MentorExerciseCard.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import DictionaryModule from '@/store/modules/dictionary'
import MentorEmployeeModule from '@/store/modules/mentor/employee'
import MentorExercisesModule from '@/store/modules/mentor/exercises'
import {
  ExerciseManagerCardResource,
  ManagerMasterGroupsMasterGroupIdExercisesGetParams,
} from '@/store/types'
import { formatDate, getCurrentMonth } from '@/utils/functions'

@Component({
  components: {
    MentorExerciseCard,
    Select,
  },
})
export default class MentorsItemExercises extends NotifyMixin {
  private exercises: ExerciseManagerCardResource[] = []
  private filter: ManagerMasterGroupsMasterGroupIdExercisesGetParams = {
    mentorId: this.mentorID,
  }

  private get mentorID () {
    return +this.$route.params.mentorID
  }

  private mounted() {
    this.fetchEmployeeMasterGroups()
  }

  private getRedirectData(exercise: ExerciseManagerCardResource) {
    return {
      name: 'manager.education.exercises.item.masterList',
      params: {
        exerciseUUID: exercise.uuid,
        groupID: this.currentMasterGroupID,
        taskUUID: exercise.tasks[0].uuid,
      },
    }
  }

  private fetchEmployeeMasterGroups(): void {
    MentorEmployeeModule.fetchEmployeeMasterGroups(this.mentorID)
      .then((response) => {
        if (this.currentMasterGroup && response.length) {
          const currentMonth = getCurrentMonth(this.currentMasterGroup.months)
          if (currentMonth) {
            this.filter = {
              ...this.filter,
              monthId: currentMonth.value as number,
            }
          }
        }
      })
      .catch(this.notifyError)
  }

  private get currentMasterGroup() {
    return MentorEmployeeModule.currentMasterGroup
  }

  private get masterGroups() {
    return MentorEmployeeModule.employeeMasterGroups.map(item => ({ name: item.title, value: item.id }))
  }

  private get exerciseStatuses() {
    return DictionaryModule.exerciseStatusesFilter
  }

  private get currentMasterGroupID() {
    if (this.currentMasterGroup) {
      return this.currentMasterGroup.id
    }
    return null
  }

  private set currentMasterGroupID(value: number | null) {
    if (value !== null) {
      const foundMasterGroup = MentorEmployeeModule.employeeMasterGroups.find(item => item.id === value)
      foundMasterGroup && MentorEmployeeModule.setEmployeeCurrentMasterGroup(foundMasterGroup)
    }
  }

  private get months() {
    return this.currentMasterGroup?.months.map(item => ({ name: formatDate(item.name, 'LLLL yyyy'), value: item.value as number }))
  }

  private fetchMentorExercises() {
    if (this.currentMasterGroupID !== null) {
      MentorExercisesModule.fetchMentorExercises({
        masterGroupId: this.currentMasterGroupID,
        params: this.filter,
      })
        .then(response => this.exercises = response)
        .catch(this.notifyError)
    }
  }

  @Watch('filter', { deep: true })
  watchFilter() {
    this.fetchMentorExercises()
  }

  @Watch('currentMasterGroupID')
  watchCurrentMasterGroupID(value: number) {
    const foundMasterGroup = MentorEmployeeModule.employeeMasterGroups.find(item => item.id === value)
    if (foundMasterGroup) {
      const currentMonth = getCurrentMonth(foundMasterGroup.months)
      if (currentMonth) {
        this.filter = {
          ...this.filter,
          monthId: currentMonth.value as number,
        }
      }
    }
  }
}
